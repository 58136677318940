import React from 'react';
// import './userCenter.css';
import { Empty as EmptyContainer } from "antd";
interface EmptyProps {
}
const Empty: React.FC<EmptyProps> = () => {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <EmptyContainer />
        </div>
    );
};
Empty.defaultProps = {
};
export default Empty;